import last from "lodash/last";
import { useRouter } from "next/router";
import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";
import "swiper/css";
import "swiper/css/navigation";

import type { entityOnelineMenuQueueItemsEdge$key } from "scmp-app/queries/__generated__/entityOnelineMenuQueueItemsEdge.graphql";

import { Container, MenuItem, StyledEntityLink, StyledSwiper, StyledSwiperSlide } from "./styles";

type Props = {
  className?: string;
  extraSlide?: ReactNode;
  onClick?: (itemLabel: string) => void;
  reference: entityOnelineMenuQueueItemsEdge$key;
};

export const EntityOnelineMenu: FunctionComponent<Props> = ({
  className,
  extraSlide,
  onClick,
  reference,
}) => {
  const { query } = useRouter();
  const items = useFragment(
    graphql`
      fragment entityOnelineMenuQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Topic {
            name
            urlAlias
          }
          ... on Section {
            name
            urlAlias
            urlRedirect {
              toUrl
            }
          }
          ...entityLink
        }
      }
    `,
    reference,
  );

  const cleanUpLabel = (string_?: string) => {
    const label = last(string_?.split(":") ?? []) ?? "";
    return label.trim();
  };

  return (
    <Container className={className}>
      <StyledSwiper cssMode={false} pagination={false} slidesPerView="auto">
        {extraSlide && <StyledSwiperSlide>{extraSlide}</StyledSwiperSlide>}
        {items.map(({ node }, index) => {
          const url = node.urlRedirect?.toUrl ?? node.urlAlias;
          return (
            <StyledSwiperSlide key={index}>
              <StyledEntityLink
                key={index}
                onClick={() => {
                  onClick?.(cleanUpLabel(node.name));
                }}
                reference={node}
              >
                <MenuItem data-active={query.asPath === url}>{cleanUpLabel(node.name)}</MenuItem>
              </StyledEntityLink>
            </StyledSwiperSlide>
          );
        })}
      </StyledSwiper>
    </Container>
  );
};

EntityOnelineMenu.displayName = "EntityOnelineMenu";
