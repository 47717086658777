import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";
import { SwiperSlide } from "swiper/react";

import { EntityLink } from "scmp-app/components/entity-link";
import IconArrowNextMobile from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/icon-arrow-next-mobile.svg?url";
import IconArrowPreviousMobile from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/icon-arrow-previous-mobile.svg?url";
import { Swiper } from "scmp-app/components/swiper";

export const StyledEntityLink = styled(EntityLink)``;

export const MenuItem = styled.span`
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 140%;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: #2957b5;
  }
`;

export const Container = styled.div`
  min-inline-size: 0;
`;

export const StyledSwiper = styled(Swiper)`
  position: relative;

  margin: 0;
  padding: 0;

  overflow-y: visible;

  .swiper-wrapper {
    block-size: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    inset-block-start: 0;

    inline-size: 36px;
    block-size: 19px;
    margin: 0;

    border-radius: 0;

    background-color: transparent;
    background-size: unset;

    box-shadow: none;

    opacity: 1;
  }

  .swiper-button-prev {
    inset-inline-start: 0;

    margin-inline-start: -9px;

    background-image: url(${IconArrowPreviousMobile});
  }

  .swiper-button-next {
    inset-inline-end: 0;

    margin-inline-end: -9px;

    background-image: url(${IconArrowNextMobile});
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  &.swiper-slide {
    display: flex;
    align-items: center;

    inline-size: auto;

    &:not(:last-child) {
      margin-inline-end: 20px;
    }
  }
`;
