/**
 * @generated SignedSource<<6e61ffe3ca32f9fcbd9f692a5ca6da06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type multimediaPodcastContentItemContent$data = {
  readonly entityId: string;
  readonly images?: ReadonlyArray<{
    readonly style: {
      readonly url: string | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly podcastSections?: ReadonlyArray<{
    readonly images: ReadonlyArray<{
      readonly style: {
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "multimediaPodcastContentItemContent";
};
export type multimediaPodcastContentItemContent$key = {
  readonly " $data"?: multimediaPodcastContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"multimediaPodcastContentItemContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "style": "100x100"
          }
        }
      ],
      "concreteType": "ImageStyle",
      "kind": "LinkedField",
      "name": "style",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": "style(filter:{\"style\":\"100x100\"})"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "multimediaPodcastContentItemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "Article",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PodcastSection",
          "kind": "LinkedField",
          "name": "podcastSections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Podcast",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "97fdb95f69c3696033b3a8e68c1ed72a";

export default node;
