/**
 * @generated SignedSource<<b99ad90476ab2d70fedaa46cecd30d33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type oneSectionTopWidgetQueue$data = ReadonlyArray<{
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent" | "sectionTopLeadPrimaryContentItemContent">;
  };
  readonly " $fragmentType": "oneSectionTopWidgetQueue";
}>;
export type oneSectionTopWidgetQueue$key = ReadonlyArray<{
  readonly " $data"?: oneSectionTopWidgetQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"oneSectionTopWidgetQueue">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "oneSectionTopWidgetQueue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "homeSecondaryContentItemContent"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "sectionTopLeadPrimaryContentItemContent"
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};

(node as any).hash = "3abf30cc33f7db25aebc1011df9202fc";

export default node;
