import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { homeFocusContentItemContent$key } from "scmp-app/queries/__generated__/homeFocusContentItemContent.graphql";

import { Container, CoverImage, Headline, ImageContainer, StyledEntityLink } from "./styles";

export type Props = {
  reference: homeFocusContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment homeFocusContentItemContent on Content {
          ...entityLink
          entityId
          ... on Video {
            duration
          }
          ...hooksContentItemProvidersContent
            @arguments(
              withHeadline: true
              withCoverImage: true
              withCoverImageSize640x360: true
              withCoverImageSize1280x720: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <StyledEntityLink onClick={() => onClick?.(content.entityId)} reference={content}>
        <Container className={className} ref={reference}>
          <ImageContainer>
            <CoverImage>
              {providers.coverImage({
                responsiveVariants: {
                  desktopUp: "size640x360",
                  mobileUp: "size1280x720",
                  tabletUp: "size640x360",
                },
              })}
            </CoverImage>
            {content?.duration &&
              providers.video?.durationOverlay({
                variant: "homepage",
              })}
          </ImageContainer>
          <Headline>
            {providers.headline({ hideTag: true, hideTooltip: true, preferSocialHeadline: true })}
          </Headline>
        </Container>
      </StyledEntityLink>
    );
  },
);

Component.displayName = "ContentItemHomeFocus";

export const ContentItemHomeFocus = withHighlightedHeadline(Component);
