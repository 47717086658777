import { FollowType, PageType, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import first from "lodash/first";
import { type FunctionComponent, useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { commentQueueQuery$key } from "scmp-app/queries/__generated__/commentQueueQuery.graphql";

import {
  CommentItems,
  Container,
  FirstRow,
  MoreLink,
  MoreLinkContainer,
  SecondRow,
  StyledContentItemComment,
  StyledContentItemHarry,
  StyledHomeFollowButton,
  Title,
  TitleContainer,
} from "./styles";

type DisplayProps = {
  withMoreCommentButton?: boolean;
};

type Props = {
  className?: string;
  reference: commentQueueQuery$key;
} & DisplayProps;

export const Comment: FunctionComponent<Props> = ({
  className,
  reference,
  withMoreCommentButton = true,
}) => {
  const data = useFragment(
    graphql`
      fragment commentQueueQuery on Query
      @argumentDefinitions(
        commentHarrySectionIds: { type: "[String]" }
        commentLimit: { type: "Int", defaultValue: 5 }
        commentQueueName: { type: "String!" }
        harrysViewLimit: { type: "Int", defaultValue: 1 }
        scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }
        withHarrysView: { type: "Boolean", defaultValue: true }
      ) {
        commentSection: section(filter: { entityId: "93" }) {
          urlAlias
          ...followButtonBase
        }
        commentQueue: queue(filter: { name: $commentQueueName }) {
          items(first: $commentLimit) {
            edges {
              node {
                ... on Content {
                  entityId
                  ...commentContentItemContent
                }
              }
            }
          }
        }
        harryContents: contents(
          contentTypes: [GALLERY]
          first: $harrysViewLimit
          filter: { sectionIds: $commentHarrySectionIds }
          articleExcludeInput: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          orderBy: { field: PUBLISHED_DATE, direction: DESC }
        ) @include(if: $withHarrysView) {
          edges {
            node {
              ... on Content {
                entityId
                ...harryContentItemContent
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const commentItems = data?.commentQueue?.items?.edges;
  const harryContent = first(data?.harryContents?.edges);
  const firstRowItems = commentItems?.slice(0, 3);
  const secondRowItems = commentItems?.slice(3, commentItems?.length);

  /** TODO: find a way to restrict page type in a shared component */
  const currentPageType = useCurrentPageType() as
    | PageType.Homepage
    | PageType.Section
    | PageType.SubSection;

  const listOfArticles = (
    data?.commentQueue?.items?.edges?.map(item => item.node.entityId) ?? []
  ).join(",");

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        list_of_articles: listOfArticles,
        page_type: currentPageType,
        widget_name: "opinion",
      },
      subcategory: "widget",
    }),
    [currentPageType, listOfArticles],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  const sendTrackingHandler = (entityId?: string, urlAlias?: string) => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        article_id: entityId,
        destination_url: getAbsoluteUrl(urlAlias),
        page_type: currentPageType,
        widget_name: "opinion",
      },
      subcategory: "widget",
    });
  };

  if (commentItems?.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.Opinion,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <TitleContainer>
          <Title pathname={data?.commentSection?.urlAlias}>OPINION</Title>
          <ClientSideSuspense>
            <StyledHomeFollowButton
              reference={data?.commentSection}
              source={
                [PageType.Section, PageType.SubSection].includes(currentPageType)
                  ? "Section"
                  : "Homepage_click"
              }
              type={FollowType.Section}
            />
          </ClientSideSuspense>
        </TitleContainer>

        <CommentItems>
          <FirstRow>
            {firstRowItems?.map(({ node }) => (
              <StyledContentItemComment
                key={node.entityId}
                onClick={(entityId, urlAlias) => {
                  sendTrackingHandler(entityId, urlAlias);
                }}
                reference={node}
              />
            ))}
          </FirstRow>
          <SecondRow>
            {secondRowItems?.map(({ node }) => (
              <StyledContentItemComment
                key={node.entityId}
                onClick={(entityId, urlAlias) => {
                  sendTrackingHandler(entityId, urlAlias);
                }}
                reference={node}
              />
            ))}
            {harryContent && (
              <StyledContentItemHarry
                onClick={(entityId, urlAlias) => {
                  sendTrackingHandler(entityId, urlAlias);
                }}
                reference={harryContent.node}
              />
            )}
          </SecondRow>
        </CommentItems>
        {withMoreCommentButton && (
          <MoreLinkContainer>
            <MoreLink pathname={data?.commentSection?.urlAlias}>MORE COMMENT</MoreLink>
          </MoreLinkContainer>
        )}
      </Container>
    </BaseLinkContextProvider>
  );
};

Comment.displayName = "Comment";
