import first from "lodash/first";
import { graphql, readInlineData } from "react-relay";

import type { helpersGetLabelContent$key } from "scmp-app/queries/__generated__/helpersGetLabelContent.graphql";

export const getLabel = (reference_: helpersGetLabelContent$key) => {
  const content = readInlineData(
    graphql`
      fragment helpersGetLabelContent on Content @inline {
        __typename
        ... on Article {
          types {
            value {
              name
            }
          }
          sections {
            value {
              name
            }
          }
        }
        ... on Gallery {
          __typename
        }
        ... on Video {
          __typename
        }
      }
    `,
    reference_,
  );

  const contentType = content?.__typename;
  switch (contentType) {
    case "Article":
      const mainArticleType = first(content?.types);

      const articleTypeName = first(mainArticleType?.value)?.name;

      const articleSections = content?.sections
        ?.flatMap(section => section?.value ?? [])
        .map(section => section?.name);

      const isArticleSectionIncludesInfographics = articleSections?.includes("Infographics");

      return articleTypeName === "Infographics" || isArticleSectionIncludesInfographics
        ? "Infographics"
        : contentType;
    case "Gallery":
      return "Pictures";
    default:
      return contentType;
  }
};
