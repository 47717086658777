import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { Logo } from "scmp-app/components/home/term-logo/styles";
import type { sectionTermLogoSection$key } from "scmp-app/queries/__generated__/sectionTermLogoSection.graphql";

type Props = {
  reference: sectionTermLogoSection$key;
};

export const SectionTermLogo: FunctionComponent<Props> = ({ reference }) => {
  const data = useFragment(
    graphql`
      fragment sectionTermLogoSection on Section {
        name
        sectionLogo: image(filter: { type: TERM_LOGO }) {
          style(filter: { style: "100x100" }) {
            url
          }
        }
      }
    `,
    reference,
  );

  if (!data.sectionLogo?.style?.url) return null;
  return <Logo alt={data.name} src={data.sectionLogo.style.url} />;
};

SectionTermLogo.displayName = "SectionTermLogo";
