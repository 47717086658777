/**
 * @generated SignedSource<<456b7d23efe31a7cbd7abee29e7d9c5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetVideoTitleVideo$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersGetVideoTitleVideo";
};
export type helpersGetVideoTitleVideo$key = {
  readonly " $data"?: helpersGetVideoTitleVideo$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetVideoTitleVideo">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetVideoTitleVideo"
};

(node as any).hash = "27368e5a2569ccfc2843e0e14e987c24";

export default node;
