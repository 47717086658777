import { atom } from "jotai";
import { graphql, readInlineData } from "react-relay";

import { currentItemAtom } from "scmp-app/lib/current-item";
import type { atomsCurrentTakeoverAdSectionsAtomBase$key } from "scmp-app/queries/__generated__/atomsCurrentTakeoverAdSectionsAtomBase.graphql";

export const currentTakeoverAdSectionsAtom = atom(get => {
  const currentItem = get(currentItemAtom);
  if (!currentItem) return [];

  const item = readInlineData<atomsCurrentTakeoverAdSectionsAtomBase$key>(
    graphql`
      fragment atomsCurrentTakeoverAdSectionsAtomBase on BaseWithApplicationAndUrlAlias @inline {
        __typename
        ... on Article {
          sections {
            value {
              entityId
            }
          }
        }
        ... on Section {
          fullSectionPath {
            entityId
          }
        }
      }
    `,
    currentItem,
  );

  switch (item.__typename) {
    case "Section":
      return item.fullSectionPath?.flatMap(s => s?.entityId ?? []) ?? [];
    case "Article":
      if (!item.sections?.length) return [];
      return item.sections
        .flatMap(section => section?.value ?? [])
        .flatMap(element => element?.entityId ?? []);
    default:
      return [];
  }
});
