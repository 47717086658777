import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";
import { HomeFollowButton } from "scmp-app/components/home/follow-button";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;

  padding: 20px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  background: #ffffff;
`;

export const Title = styled.div`
  margin-block-end: 20px;

  color: #00000080;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;

  padding-block: 6px;
  padding-inline: 8px;

  border-radius: 3px;

  background: #f2f2f2;
`;

export const StyledEntityLink = styled(EntityLink)`
  color: #000000;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;
`;

export const StyledHomeFollowButton = styled(HomeFollowButton)`
  margin-inline-start: 8px;
  button {
    margin-block: auto;
    padding: 0;
  }
`;
