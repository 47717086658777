/**
 * @generated SignedSource<<8429951e5ed3a8bf6349434158768a19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type focusArticleHomeFocusArticleQuery$data = {
  readonly focus: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly urlAlias?: string;
          readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsLiveArticle" | "helpersGetArticleLogoArticle" | "helpersGetArticleTitleArticle" | "helpersGetPodcastTitlePodcast" | "helpersGetVideoTitleVideo" | "homeFocusContentItemContent">;
        };
      }>;
    } | null | undefined;
    readonly settings: unknown | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "focusArticleHomeFocusArticleQuery";
};
export type focusArticleHomeFocusArticleQuery$key = {
  readonly " $data"?: focusArticleHomeFocusArticleQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"focusArticleHomeFocusArticleQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionSegment",
  "kind": "LinkedField",
  "name": "sections",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlAlias",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "focusQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "focusArticleHomeFocusArticleQuery",
  "selections": [
    {
      "alias": "focus",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "focusQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settings",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "homeFocusContentItemContent"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersGetArticleTitleArticle",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ArticleTypeSegment",
                              "kind": "LinkedField",
                              "name": "types",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ArticleType",
                                  "kind": "LinkedField",
                                  "name": "value",
                                  "plural": true,
                                  "selections": (v1/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v2/*: any*/)
                          ],
                          "type": "Article",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersGetPodcastTitlePodcast",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            }
                          ],
                          "type": "Podcast",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersGetVideoTitleVideo",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "type": "Video",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersCheckIsLiveArticle",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "flattenTypeIds",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "LiveArticle",
                              "kind": "LinkedField",
                              "name": "liveArticle",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "status",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "Article",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersGetArticleLogoArticle",
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "SectionSegment",
                              "kind": "LinkedField",
                              "name": "sections",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Section",
                                  "kind": "LinkedField",
                                  "name": "value",
                                  "plural": true,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "name",
                                      "storageKey": null
                                    },
                                    (v3/*: any*/),
                                    {
                                      "alias": null,
                                      "args": [
                                        {
                                          "kind": "Literal",
                                          "name": "filter",
                                          "value": {
                                            "type": "TERM_LOGO"
                                          }
                                        }
                                      ],
                                      "concreteType": "Image",
                                      "kind": "LinkedField",
                                      "name": "image",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": [
                                            {
                                              "kind": "Literal",
                                              "name": "filter",
                                              "value": {
                                                "style": "100x100"
                                              }
                                            }
                                          ],
                                          "concreteType": "ImageStyle",
                                          "kind": "LinkedField",
                                          "name": "style",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "url",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": "style(filter:{\"style\":\"100x100\"})"
                                        }
                                      ],
                                      "storageKey": "image(filter:{\"type\":\"TERM_LOGO\"})"
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "Article",
                          "abstractKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/)
                      ],
                      "type": "Content",
                      "abstractKey": "__isContent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:1)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "809df90c888eb2ef411871bcc60bc425";

export default node;
