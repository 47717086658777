import first from "lodash/first";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseImage } from "scmp-app/components/common/base-image";
import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { multimediaPodcastContentItemContent$key } from "scmp-app/queries/__generated__/multimediaPodcastContentItemContent.graphql";

import { Container, Content, Headline, Label, StyledEntityLink, StyledFigure } from "./styles";

export type Props = {
  reference: multimediaPodcastContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment multimediaPodcastContentItemContent on Content {
          entityId
          urlAlias
          ... on Article {
            images {
              style(filter: { style: "100x100" }) {
                url
              }
            }
          }
          ... on Podcast {
            podcastSections {
              images {
                style(filter: { style: "100x100" }) {
                  url
                }
              }
              name
            }
          }
          ...entityLink
          ...hooksContentItemProvidersContent @arguments(withHeadline: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    const firstPodcastSection = first(content?.podcastSections);
    const sectionName = firstPodcastSection?.name ?? "";
    const podcastImageUrl = first(firstPodcastSection?.images)?.style?.url ?? "";
    const articleImageUrl = first(content?.images)?.style?.url ?? "";
    const imageUrl = podcastImageUrl || articleImageUrl;

    return (
      <Container className={className} ref={reference}>
        <StyledEntityLink
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
          reference={content}
        >
          <Content>
            <Label>PODCAST</Label>
            <Headline>
              {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
            </Headline>
          </Content>
          <StyledFigure $aspectRatio="1/1">
            <BaseImage
              $aspectRatio="1/1"
              $objectFit="cover"
              alt={sectionName}
              lazyload
              src={imageUrl}
            />
          </StyledFigure>
        </StyledEntityLink>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeMultimediaPodcast";

export const ContentItemMultimediaPodcast = withHighlightedHeadline(Component);
