/**
 * @generated SignedSource<<bdd4a7a15637f15709a9824b21afb8b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type entityOnelineMenuQueueItemsEdge$data = ReadonlyArray<{
  readonly node: {
    readonly name?: string;
    readonly urlAlias?: string;
    readonly urlRedirect?: {
      readonly toUrl: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  };
  readonly " $fragmentType": "entityOnelineMenuQueueItemsEdge";
}>;
export type entityOnelineMenuQueueItemsEdge$key = ReadonlyArray<{
  readonly " $data"?: entityOnelineMenuQueueItemsEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"entityOnelineMenuQueueItemsEdge">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlAlias",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "entityOnelineMenuQueueItemsEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "type": "Topic",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UrlRedirect",
              "kind": "LinkedField",
              "name": "urlRedirect",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "toUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Section",
          "abstractKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityLink"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};
})();

(node as any).hash = "8b1f0c3959045fd0f440229554db1bc3";

export default node;
