import styled from "@emotion/styled";

import { setFullViewportWidthStyles, ZIndexLayer } from "scmp-app/lib/styles";

export const Container = styled.div`
  position: fixed;
  inset-block-end: 0;
  inset-inline-start: 0;
  z-index: ${ZIndexLayer.TakeoverAd};

  inline-size: 100%;
`;

type AdSlotProps = {
  $height: number;
};
export const AdSlotContainer = styled.div<AdSlotProps>`
  ${setFullViewportWidthStyles()}

  block-size: ${props => `${props.$height}px`};

  background-color: #f6f6f6;
`;

export const CloseButton = styled.button`
  position: absolute;
  inset-inline-end: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 20px;
  block-size: 20px;
  margin: 10px;
  padding: 4px;

  border-radius: 20px;

  background-color: rgba(255, 255, 255, 0.6);

  cursor: pointer;

  ${props => props.theme.breakpoints.up("desktop")} {
    inline-size: 28px;
    block-size: 28px;
    padding: 7px;
  }
`;
