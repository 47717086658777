/**
 * @generated SignedSource<<00ac0b39c53f6bdd5311106f1c367ae8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsLiveArticle$data = {
  readonly flattenTypeIds: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly liveArticle: {
    readonly status: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "helpersCheckIsLiveArticle";
};
export type helpersCheckIsLiveArticle$key = {
  readonly " $data"?: helpersCheckIsLiveArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsLiveArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsLiveArticle"
};

(node as any).hash = "a7ee619c073a12e20a5a96f7eb08798d";

export default node;
