import { notEmpty, theme } from "@product/scmp-sdk";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import type { topStoriesBlockQueueItemsEdge$key } from "scmp-app/queries/__generated__/topStoriesBlockQueueItemsEdge.graphql";

import {
  AdsSlotContainer,
  Container,
  LeftContainer,
  MLrecAdContainer,
  RightContainer,
  StyledTopStoriesItem,
} from "./styles";

type Slots = {
  LastItemAd?: ReactNode;
  MLrecAd?: ReactNode;
};

type Props = {
  adZone?: string;
  className?: string;
  isShowAds?: boolean;
  isShowMLrecAd?: boolean;
  onClick: (
    entityId?: string,
    urlAlias?: string,
    isSponsorArticle?: boolean,
    index?: number,
  ) => void;
  reference: topStoriesBlockQueueItemsEdge$key;
  slots?: Slots;
  withCaption?: boolean;
};

export const TopStoriesBlock = forwardRef<HTMLDivElement, Props>(
  (
    {
      adZone,
      className,
      isShowAds = false,
      isShowMLrecAd = false,
      onClick,
      reference: reference_,
      slots,
      withCaption = true,
    },
    reference,
  ) => {
    const items = useFragment(
      graphql`
        fragment topStoriesBlockQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
          node {
            ... on Content {
              ...topStoriesItemContent
            }
          }
        }
      `,
      reference_,
    );

    const renderMLrecAd = () => {
      if (!isShowMLrecAd) return null;
      if (slots?.MLrecAd) return <MLrecAdContainer>{slots.MLrecAd}</MLrecAdContainer>;
      return (
        <MLrecAdContainer>
          <AdSlot
            adUnit="m_lrec1"
            breakpoint={theme.breakpoints.only("mobile")}
            sizes={[[300, 250], "fluid"]}
            targeting={{
              paid: TargetingPaidType.Free,
            }}
            zone={adZone}
          />
        </MLrecAdContainer>
      );
    };

    const renderLastItemAd = () => {
      if (!isShowAds) return null;
      if (slots?.LastItemAd) return <AdsSlotContainer>{slots.LastItemAd}</AdsSlotContainer>;
      return (
        <AdsSlotContainer>
          <AdSlot
            adUnit="d_native2c"
            sizes={[[1, 1], "fluid"]}
            targeting={{
              paid: TargetingPaidType.Free,
            }}
            zone={adZone}
          />
        </AdsSlotContainer>
      );
    };

    if (!items) return null;

    const [first, second, ...rest] = items.filter(notEmpty).map(({ node }) => node);

    return (
      <Container className={className} ref={reference}>
        <LeftContainer $isShowMLrecAd={isShowMLrecAd}>
          {first && (
            <StyledTopStoriesItem
              onClick={(entityId, urlAlias, isSponsorArticle) => {
                onClick(entityId, urlAlias, isSponsorArticle, 0);
              }}
              reference={first}
              variant="primary"
              withCaption={withCaption}
            />
          )}
          {renderMLrecAd()}
          {second && (
            <StyledTopStoriesItem
              onClick={(entityId, urlAlias, isSponsorArticle) => {
                onClick(entityId, urlAlias, isSponsorArticle, 1);
              }}
              reference={second}
              variant="secondary"
            />
          )}
        </LeftContainer>
        <RightContainer>
          {rest.map((item, itemIndex) => (
            <StyledTopStoriesItem
              isShowImage={!isShowAds && itemIndex === rest.length - 1}
              key={itemIndex}
              onClick={(entityId, urlAlias, isSponsorArticle) => {
                onClick(entityId, urlAlias, isSponsorArticle, itemIndex + 2);
              }}
              reference={item}
              variant="secondary"
            />
          ))}
          {renderLastItemAd()}
        </RightContainer>
      </Container>
    );
  },
);

TopStoriesBlock.displayName = "TopStoriesBlock";
