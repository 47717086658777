import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto, fontRobotoCondensed } from "@product/scmp-sdk";

import { Figure } from "scmp-app/components/content/content-cover-image/styles";
import { EntityLink } from "scmp-app/components/entity-link";

import Gallery from "./gallery.svg?url";

export const Container = styled.div`
  display: block;
  ${props => props.theme.breakpoints.up("tablet")} {
    display: flex;
    flex: 1;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    display: block;
    flex: initial;
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: flex;
  flex-direction: column;

  color: #000000;
`;

export const Label = styled.div`
  margin-block-end: 8px;

  color: #4585ff;
  font-weight: 700;
  font-size: 13px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;
  text-transform: uppercase;
`;

export const Headline = styled.div`
  margin-block-end: 12px;

  font-weight: 400;
  font-size: 15px;
  font-family: ${fontMerriweather};
  line-height: 21px;
`;

type CoverImageProps = {
  $isVideo: boolean;
};

export const CoverImage = styled.div<CoverImageProps>`
  position: relative;

  cursor: pointer;
  ${Figure} {
    aspect-ratio: 3/2;
  }
`;

export const GalleryContainer = styled.div`
  position: absolute;
  inset-block-start: 8px;
  inset-inline-end: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  inline-size: 60px;
  block-size: 60px;

  border-radius: 225px;

  background: rgba(0, 0, 0, 0.8);
`;

export const GalleryTotal = styled.div`
  margin-block-start: 4px;

  color: #ffffff;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 16px;
`;

export const StyledGalleryIcon = styled.div`
  inline-size: 16px;
  block-size: 16px;

  background-image: url(${Gallery});
  background-repeat: no-repeat;
  background-position: center;
`;
