/**
 * @generated SignedSource<<d01c0ea14210d0783204074d3abe2841>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionTermLogoSection$data = {
  readonly name: string;
  readonly sectionLogo: {
    readonly style: {
      readonly url: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "sectionTermLogoSection";
};
export type sectionTermLogoSection$key = {
  readonly " $data"?: sectionTermLogoSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionTermLogoSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionTermLogoSection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "sectionLogo",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "type": "TERM_LOGO"
          }
        }
      ],
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "100x100"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": "style(filter:{\"style\":\"100x100\"})"
        }
      ],
      "storageKey": "image(filter:{\"type\":\"TERM_LOGO\"})"
    }
  ],
  "type": "Section",
  "abstractKey": null
};

(node as any).hash = "587a344f1e3dd880811032c0ce2c6055";

export default node;
