/**
 * @generated SignedSource<<4eb0e0f14cc6261fad0586f7b5c5030e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetPodcastTitlePodcast$data = {
  readonly __typename: "Podcast";
  readonly " $fragmentType": "helpersGetPodcastTitlePodcast";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "helpersGetPodcastTitlePodcast";
};
export type helpersGetPodcastTitlePodcast$key = {
  readonly " $data"?: helpersGetPodcastTitlePodcast$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetPodcastTitlePodcast">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetPodcastTitlePodcast"
};

(node as any).hash = "b76a153ed0eaca688b83539b9481daf6";

export default node;
