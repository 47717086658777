import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { commentContentItemContent$key } from "scmp-app/queries/__generated__/commentContentItemContent.graphql";

import {
  ActionBar,
  AuthorImages,
  AuthorLinks,
  Container,
  Content,
  ContentContainer,
  Duration,
  Headline,
  StyledEntityLink,
  StyledVideoEntityLink,
  StyledVideoImage,
  StyledVideoImageContainer,
  VideoCommentContainer,
  VideoContentContainer,
  VideoHeadline,
} from "./styles";

export type Props = {
  reference: commentContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment commentContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withAuthorLink: true
              withHeadline: true
              withAuthorImage: true
              withActionBar: true
            )
          ... on Video {
            headline
            duration
            image(filter: { type: MAIN_IMAGE }) {
              alt
              style(filter: { style: "1200x800" }) {
                url
              }
            }
          }
        }
      `,
      reference_,
    );

    const providers = useContentItemProviders(content);

    return content.image ? (
      <VideoCommentContainer className={className} ref={reference}>
        <VideoContentContainer>
          <StyledVideoEntityLink
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
            reference={content}
          >
            <VideoHeadline>{content.headline}</VideoHeadline>
            <StyledVideoImageContainer>
              {content.image.style?.url && (
                <StyledVideoImage alt={content.image?.alt ?? ""} src={content.image.style.url} />
              )}
              {providers.video?.videoPlayIcon()}
              <Duration>{content.duration}</Duration>
            </StyledVideoImageContainer>
          </StyledVideoEntityLink>
        </VideoContentContainer>
      </VideoCommentContainer>
    ) : (
      <Container className={className} ref={reference}>
        <ContentContainer>
          <Content>
            <AuthorLinks onClick={() => onClick?.(content.entityId, content.urlAlias)}>
              {providers.author?.({ ignoreAuthorType: true, isMultiple: true })}
            </AuthorLinks>
            <StyledEntityLink
              onClick={() => onClick?.(content.entityId, content.urlAlias)}
              reference={content}
            >
              <Headline>
                {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
              </Headline>
            </StyledEntityLink>
          </Content>
          <AuthorImages>
            {providers.authorImage?.({
              ignoreAuthorType: true,
              isMultiple: true,
              withOutline: true,
            })}
          </AuthorImages>
        </ContentContainer>
        <ActionBar>
          {providers.actionBar({
            commentVariant: "reverse",
            isBookmarkVisible: false,
            isCommentsVisible: true,
            isIconVisible: false,
          })}
        </ActionBar>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeComment";

export const ContentItemComment = withHighlightedHeadline(Component);
