/**
 * @generated SignedSource<<01dcab671238335e2d4357f26b238ecb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersGetArticleTitleArticle$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersGetArticleTitleArticle";
};
export type helpersGetArticleTitleArticle$key = {
  readonly " $data"?: helpersGetArticleTitleArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersGetArticleTitleArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersGetArticleTitleArticle"
};

(node as any).hash = "d7b9cb48b9becb434b85d1c2e20a1711";

export default node;
